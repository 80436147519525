
import React, { Fragment } from 'react';
import { Dialog, Grid, } from '@mui/material'
import Contact from './contact';
import RelatedProject from './related';


const ProjectSingle = ({ maxWidth, open, onClose, title, Img, description, client, completion, consult, duration, location, project_type, share, psub1img1, psub1img2, }) => {

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        const day = date.toLocaleString('en-US', { day: '2-digit' });
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };
    
    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
                data-lenis-prevent
            >
                <Grid className="modalBody modal-body project-modal">
                    <div className="modal-close-btn">
                        <button onClick={onClose}><i className='ti-close'></i></button>
                    </div>
                    <div className="wpo-project-single-area">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 col-12">
                                    <div className="wpo-project-single-wrap">
                                        <div className="wpo-project-single-item">
                                            <div className="row mb-8">
                                                <div className="col-lg-7">
                                                    <div className="wpo-project-single-title">
                                                        <h3>{title} Project</h3>
                                                    </div>
                                                    <p
                                                        dangerouslySetInnerHTML={{ __html: description }}
                                                    />
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className="wpo-project-single-content-des-right">
                                                        <ul>
                                                            <li>Location :<span>{location}</span></li>
                                                            <li>Client :<span>{client}</span></li>
                                                            <li>Consult :<span>{consult}</span></li>
                                                            <li>Project Type :<span>{project_type}</span></li>
                                                            <li>Duration :<span>{duration}</span></li>
                                                            <li>Completion :<span>{formatDate(completion)}</span></li>
                                                            <li>Share :<span>{share}</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpo-project-single-main-img">
                                                <img src={Img} alt="" />
                                            </div>
                                        </div>
                                        {/* <div className="wpo-project-single-item list-widget">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="wpo-project-single-title">
                                                        <h3>Our Strategies</h3>
                                                    </div>
                                                    <p>Massa volutpat odio facilisis purus sit elementum. Non, sed velit dictum
                                                        quam. Id
                                                        risus pharetra est, at rhoncus, nec ullamcorper tincidunt. Id aliquet duis
                                                        sollicitudin diam.</p>
                                                    <ul>
                                                        <li>Non saed velit dictum quam risus pharetra esta.</li>
                                                        <li>Id risus pharetra est, at rhoncus, nec ullamcorper tincidunt.</li>
                                                        <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                                                        <li>Massa volutpat odio facilisis purus sit elementum.</li>
                                                        <li>Elit curabitur amet risus bibendum.</li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="wpo-project-single-item-quote mt-8">
                                                        <p>"Amazing looking theme and instantly turns your application into a great
                                                            looking one. Really shows that pro_ fessionals built this theme up. Very
                                                            happy with the way the theme looks ."</p>
                                                        <span>Robert - <span>Yellow Theme</span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpo-project-single-item">
                                            <div className="wpo-project-single-title">
                                                <h3>Our approach</h3>
                                            </div>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consequat suspendisse aenean
                                                tellus augue morbi risus. Sit morbi vitae morbi sed urna sed purus. Orci facilisi
                                                eros sed pellentesque. Risus id sed tortor sed scelerisque. Vestibulum elit
                                                elementum, magna id viverra non, velit. Pretium, eros, porttitor fusce auctor vitae
                                                id. Phasellus scelerisque nibh eleifend vel enim mauris purus. Rutrum vel sem
                                                adipiscing nisi vulputate molestie scelerisque molestie ultrices. Eu, fusce
                                                vulputate diam interdum morbi ac a.</p>
                                        </div>
                                        <div className="wpo-project-single-gallery">
                                            <div className="grid row">
                                                <div className="col-lg-6">
                                                    <div className="wpo-p-details-img">
                                                        <img src={psub1img1} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="wpo-p-details-img">
                                                        <img src={psub1img2} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpo-project-single-item list-widget">
                                            <div className="row">
                                                <div className="grid col-lg-6 mb-8">
                                                    <div className="wpo-project-single-title">
                                                        <h3>Resieved goals</h3>
                                                    </div>
                                                    <ul>
                                                        <li>Non saed velit dictum quam risus pharetra esta.</li>
                                                        <li>Id risus pharetra est, at rhoncus, nec ullamcorper tincidunt.</li>
                                                        <li>Hac nibh fermentum nisi, platea condimentum cursus.</li>
                                                        <li>Massa volutpat odio facilisis purus sit elementum.</li>
                                                    </ul>
                                                </div>
                                                <div className="grid col-lg-6 list-widget-s">
                                                    <div className="wpo-project-single-title">
                                                        <h3>Results</h3>
                                                    </div>
                                                    <ul>
                                                        <li>Mauris dignissim blandit cursus imperdiet accumsan lorem.</li>
                                                        <li>Nam id in non sed cras purus nunc et.</li>
                                                        <li>Mauris orci, cursus nisl odio est adipiscing gravida magna eget.</li>
                                                        <li>Quis mauris vel felis convallis nulla dignissim.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <RelatedProject />
                                        <div className="wpo-project-single-item">
                                            <div className="wpo-project-contact-area">
                                                <div className="wpo-contact-title">
                                                    <h2 className='heading-font font-bold text-3xl md:text-[25px] sm:text-[22px] mb-[15px]'>Have project in mind? Let's discuss</h2>
                                                    <p>Get in touch with us to see how we can help you with your project</p>
                                                </div>
                                                <div className="wpo-contact-form-area">
                                                    <Contact />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default ProjectSingle;

