
import React, { Fragment } from 'react';
import { Dialog, Grid, } from '@mui/material'
import './style.css'
import Contact from './contact';
import Services from '../../api/service'


const ServiceSingle = ({ maxWidth, open, onClose, title, image, description }) => {


    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
                data-lenis-prevent
            >
                <Grid className="modalBody modal-body">
                    <div className="modal-close-btn">
                        <button onClick={onClose}><i className='ti-close'></i></button>
                    </div>
                    <div className="wpo-service-single-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="wpo-service-single-wrap">
                                        <div className="wpo-service-single-item">
                                            <div className="wpo-service-single-main-img">
                                                <img src={image} alt="" />
                                            </div>
                                            <div className="wpo-service-single-title">
                                                <h3>{title}</h3>
                                            </div>
                                            {/* <p>{description}</p> */}
                                            <p
                                                dangerouslySetInnerHTML={{ __html: description }}
                                            />

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default ServiceSingle

