import React, { useState } from 'react';
import apiService from '../../api/apiService';
import { useData } from '../../contexts/DataContext';

const ContactForm = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        service: '',
        phone: '',
        message: ''
    });

    const [errors, setErrors] = useState({});

    const { data, loading, error } = useData();
    const services = data?.newData?.data?.services || [];

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
    };

    const submitHandler = async e => {
        e.preventDefault();

        setErrors({});

        const newErrors = {};
        for (const key in forms) {
            if (!forms[key]) {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            const { name, email, phone, service, message } = forms;
            await apiService.post('/user/contactus', {
                name,
                email,
                number: phone,
                service: service,
                message
            });
            setForms({
                name: '',
                email: '',
                service: '',
                phone: '',
                message: ''
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="wpo-contact-form-area">
            <form method="post" className="contact-validation-active" onSubmit={submitHandler}>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="form-field">
                            <input
                                value={forms.name}
                                type="text"
                                name="name"
                                className="form-control"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                placeholder="Your Name*" />
                            {errors.name && <span className="error-message text-danger">{errors.name}</span>} 
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-field">
                            <input
                                value={forms.email}
                                type="email"
                                name="email"
                                className="form-control"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                placeholder="Your Email*" />
                            {errors.email && <span className="error-message text-danger">{errors.email}</span>} 
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-field">
                            <input
                                value={forms.phone}
                                type="tel"
                                name="phone"
                                className="form-control"
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                placeholder="Your Phone*" />
                            {errors.phone && <span className="error-message text-danger">{errors.phone}</span>} 
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-field">
                            <select
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={forms.service}
                                name="service"
                                className="form-control">
                                <option value="">Choose a Service</option>
                                {services.map(service => (
                                    <option key={service.id} value={service.title}>
                                        {service.title}
                                    </option>
                                ))}
                            </select>
                            {errors.service && <span className="error-message text-danger">{errors.service}</span>} 
                        </div>
                    </div>
                    <div className="fullwidth">
                        <div className="form-field">
                            <textarea
                                onBlur={changeHandler}
                                onChange={changeHandler}
                                value={forms.message}
                                name="message"
                                className="form-control"
                                placeholder="Message...">
                            </textarea>
                            {errors.message && <span className="error-message text-danger">{errors.message}</span>} 
                        </div>
                    </div>
                </div>
                <div className="submit-area">
                    <button type="submit" className='submit-btn mouseCursor'><span>Free Consultation</span></button>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;
