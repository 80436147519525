import React from 'react'
import { Link } from 'react-router-dom'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Footer = (props) => {
    return (
        <section className="footer-section">
            <div className="container">
                <h1 className="d-none">hidden content</h1>
                <div className="row align-items-center">
                    <div className="col-md-6 col-12">
                        <p>©copyright by <Link onClick={ClickHandler} to="/">anoor</Link></p>
                    </div>
                    <div className="col-md-6 col-12">
                        <ul>
                            <li><Link onClick={ClickHandler} to="/">facebook</Link></li>
                            <li><Link onClick={ClickHandler} to="/">Twitter</Link></li>
                            <li><Link onClick={ClickHandler} to="/">instagram</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;