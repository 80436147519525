import React from 'react';
import CountUp, { useCountUp } from 'react-countup';
import { useData } from '../../contexts/DataContext';

const FunFactSection = (props) => {
    const { data, loading, error } = useData();

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading data...</div>;

    const settings = data?.settings || [];

    const getSettingValue = (key) => {
        const setting = settings.find(item => item.key === key);
        return setting ? setting.value : '';
    };

    const funFacts = [
        {
            title: getSettingValue('Funfact_CompleteProjects').replace(/\D/g, ''),
            subTitle: 'Complete Projects',
            symbol: getSettingValue('Funfact_CompleteProjects').replace(/\d/g, ''),
        },
        {
            title: getSettingValue('Funfact_AverageRating').split('/')[0],
            subTitle: 'Average Rating',
            symbol: `/${getSettingValue('Funfact_AverageRating').split('/')[1]}`,
        },
        {
            title: getSettingValue('Funfact_HappyClients').replace(/\D/g, ''),
            subTitle: 'Happy Clients',
            symbol: getSettingValue('Funfact_HappyClients').replace(/\d/g, ''),
        },
        {
            title: getSettingValue('Funfact_WinningAwards').replace(/\D/g, ''),
            subTitle: 'Winning Awards',
            symbol: getSettingValue('Funfact_WinningAwards').replace(/\d/g, ''),
        },
    ];

    return (
        <section className="funfact-section" id="FunFact">
            <div className="container">
                <div className="row">
                    {funFacts.map((funfact, index) => (
                        <div className="col-xl-3 col-lg-6 col-md-6 col-12" key={index}>
                            <div className="item">
                                <h2>
                                    <span><CountUp end={parseInt(funfact.title)} enableScrollSpy /></span>
                                    {funfact.symbol}
                                </h2>
                                <h4>{funfact.subTitle}</h4>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <span id="counter" className="d-none" />
        </section>
    );
}

export default FunFactSection;
