import React from 'react'
import ContactForm from '../ContactFrom/ContactForm'
import cIcon1 from '../../images/contact/1.png'
import cIcon2 from '../../images/contact/img-1.png'
import cIcon3 from '../../images/contact/2.png'
import cIcon4 from '../../images/contact/img-2.png'
import cIcon5 from '../../images/contact/3.png'
import cIcon6 from '../../images/contact/img-3.png'
import { useData } from '../../contexts/DataContext'


const ContactArea = (props) => {

    const { data, loading, error } = useData();

    const settings = data?.settings || [];

    const phone = settings.find(item => item.key === 'Contact_Phone')?.value || 'Phone not available';
    const email = settings.find(item => item.key === 'Contact_Email')?.value || 'Email not available';
    const address = settings.find(item => item.key === 'Contact_Info')?.value || 'Address not available';

    return (
        <section className="contact-section" id="Contact">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Get in Touch</h2>
                            <h3>Contact With Me</h3>
                        </div>
                    </div>
                </div>
                <div className="contact-info">
                    <div className="info-item">
                        <div className="icon">
                            <img src={cIcon1} alt="icon" className="white-img" />
                            <img src={cIcon2} alt="icon" className="black-img" />
                        </div>
                        <div className="text">
                            <h2>Contact Info</h2>
                            <span>{address}</span>
                        </div>
                    </div>
                    <div className="info-item">
                        <div className="icon">
                            <img src={cIcon3} alt="icon" className="white-img" />
                            <img src={cIcon4} alt="icon" className="black-img" />
                        </div>
                        <div className="text">
                            <h2>Email Address</h2>
                            <span>{email}</span>
                        </div>
                    </div>
                    <div className="info-item">
                        <div className="icon">
                            <img src={cIcon5} alt="icon" className="white-img" />
                            <img src={cIcon6} alt="icon" className="black-img" />
                        </div>
                        <div className="text">
                            <h2>Phone</h2>
                            <span>{phone}</span>
                        </div>
                    </div>
                </div>
                <div className="contact-form-area">
                    <ContactForm btnClass={props.btnClass} />
                </div>
            </div>
        </section>
    )
}

export default ContactArea;