import React, { createContext, useContext, useEffect, useState } from 'react';
import apiService from '../api/apiService';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState({ allData: null, newData: null, settings: null });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const allData = await apiService.get('/user/getalldata');
                const newData = await apiService.get('/user/getnewdata');

                const settingsResponse = await apiService.get('/user/setting');
                const settings = settingsResponse.data;

                setData({ allData, newData, settings });
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <DataContext.Provider value={{ data, loading, error }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => useContext(DataContext);
