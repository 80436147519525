import React, { useState } from 'react';
import BlogSingle from "../BlogSingle";
import { useData } from '../../contexts/DataContext';

const BlogSection = () => {
    const { data, loading, error } = useData();
    const [open, setOpen] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);

    const blogs = data?.allData?.data?.blogs || [];

    const handleClose = () => {
        setOpen(false);
        setSelectedBlog(null);
    };

    const handleClickOpen = (blog) => {
        setOpen(true);
        setSelectedBlog(blog);
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        const day = date.toLocaleString('en-US', { day: '2-digit' });
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };


    return (
        <section className="blog-section" id="Blog">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Blog</h2>
                            <h3>Latest News</h3>
                        </div>
                    </div>
                </div>
                <div className="blog-wrap">
                    {blogs.slice(0, 3).map((blog) => (
                        <div className="item" key={blog.id}>
                            <div className="image">
                                <img src={blog.image || '/path/to/placeholder/image.png'} alt={blog.title} />
                            </div>
                            <div className="content">
                                <ul className="date">
                                    <li>{formatDate(blog.date)}</li>
                                    <li>{blog.author_name}</li>
                                </ul>
                                <h2 onClick={() => handleClickOpen(blog)}>{blog.title}</h2>
                                <h4 onClick={() => handleClickOpen(blog)}>Read More</h4>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {selectedBlog && (
                <BlogSingle 
                    open={open} 
                    onClose={handleClose} 
                    title={selectedBlog.title} 
                    Img={selectedBlog.image} 
                    date={selectedBlog.date} 
                    author={selectedBlog.author_name} 
                    description={selectedBlog.description} 
                    // comment={selectedBlog.description} 
                />
            )}
        </section>
    );
};

export default BlogSection;
