import React, { useEffect, useState } from 'react';
import ServiceSingle from '../ServiceSingle';
import { useData } from '../../contexts/DataContext';

const ServiceSection = () => {
    const { data, loading, error } = useData();

    const [open, setOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    const services = data?.newData?.data?.services || [];
    const settingData = data?.settingData?.data || [];
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = (service) => {
        setOpen(true);
        setSelectedService(service);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <section className="service-section" id="Services">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>My Services</h2>
                            <h3>What We Do</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {services.slice(0, 6).map((service) => (
                        <div className="col-xl-6 col-lg-12 col-md-6 col-12" key={service.id}>
                            <div className="service_item">
                                <div className="icon">
                                    {service.image && (
                                        <img src={service.image} alt="service" className="white-img" />
                                    )}
                                </div>
                                <div className="content">
                                    <h2 onClick={() => handleClickOpen(service)}>{service.title}</h2>
                                    <p>{service.sub_title}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {selectedService && (
                <ServiceSingle
                    open={open}
                    onClose={handleClose}
                    title={selectedService.title}
                    dImg={selectedService.image}
                    description={selectedService.description}
                />
            )}
        </section>
    );
};

export default ServiceSection;
