import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import quote from '../../images/testimonial/quote.png';
import apiService from '../../api/apiService';
import ts3 from '../../images/testimonial/3.jpg'
import { useData } from '../../contexts/DataContext';

const Testimonial = () => {
    const { data, loading, error } = useData();

    const testimonials = data?.newData?.data?.testimonials || [];

    const settings = {
        dots: false,
        arrows: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        fade: true,
    };

    return (
        <section className="testimonial-section" id="Testimonial">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Testimonial</h2>
                            <h3>What My Client Says</h3>
                        </div>
                    </div>
                </div>
                <div className="testimonial-wrap testimonial-slider">
                    <Slider {...settings}>
                        {testimonials.map((testimonial) => (
                            <div className="testimonial-card" key={testimonial.id}>
                                <div className="top-content">
                                    <div className="image">
                                        <img src={testimonial.image ? testimonial.image : ts3} alt={testimonial.title} />
                                    </div>
                                    <div className="text">
                                        <h2>{testimonial.title}</h2>
                                        <span>{testimonial.role || 'Client'}</span>
                                    </div>
                                </div>
                                {/* <h3>“{testimonial.description.replace(/<\/?p>/g, '')}”</h3> */}
                                <div dangerouslySetInnerHTML={{ __html: testimonial.description }} />
                                <div className="icon">
                                    <img src={quote} alt="quote" />
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;
