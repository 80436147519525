import React from 'react';
import AllRoute from '../router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataProvider } from '../../contexts/DataContext';

const App = () => {

  return (
    <div className="App" id='scrool'>
      <DataProvider>
        <AllRoute />
      </DataProvider>
      <ToastContainer />
    </div>
  );
}

export default App;