import React, { Fragment, useState, useEffect } from 'react';
import { ReactLenis } from '@studio-freight/react-lenis'
import Header from '../../components/header/Header';
import About from '../../components/about/about';
import FunFactSection from '../../components/FunFact/FunFact';
import BlogSection from '../../components/BlogSection/BlogSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import ContactArea from '../../components/ContactArea';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';


const HomePage = () => {

    const [switchActive, setSwitchActiveState] = useState(
        JSON.parse(localStorage.getItem("switch"))
    );

    const switcherHandler = () => {
        setSwitchActiveState(!switchActive);
        localStorage.setItem("switch", !switchActive);
    };



    useEffect(() => {
        if (switchActive) {
            document.body.classList.add(`dark`);
            document.body.classList.remove(`light`);
        } else {
            document.body.classList.add(`light`);
            document.body.classList.remove(`dark`);
        }
    }, [switchActive]);

    return (
        <Fragment>
            <Header />
            <div className="page-content">
                <About />
                <ServiceSection />
                <ProjectSection />
                <Testimonial />
                <FunFactSection />
                <BlogSection />
                <ContactArea />
                <Footer />
            </div>
            <Scrollbar />
            <div className="switch-btn-wrap">
                <button onClick={switcherHandler} className="switch-btn" data-tooltip-id="switch" data-tooltip-content="Color Switcher"><i
                    className={`${switchActive ? "ti-shine" : "ti-star"}`}></i></button>
            </div>
        </Fragment>
    )
};
export default HomePage;