import React from 'react';
import about from '../../images/about-1.jpg'
import about2 from '../../images/about-2.jpg'
import { useData } from '../../contexts/DataContext';


const About = (props) => {

    const { data, loading, error } = useData();

    const settings = data?.settings || [];

    const aboutTitle = settings.find(item => item.key === "About_Title")?.value || "Default Title";
    const aboutSubtitle = settings.find(item => item.key === "About_Subtitle")?.value || "Default Subtitle";
    const aboutlogotoptitle = settings.find(item => item.key === "About_logo_Top_text")?.value || "Default Subtitle";
    const aboutlogobottomtitle = settings.find(item => item.key === "Aboutlogo_Bottomtext")?.value || "Default Subtitle";
    const aboutDescription = settings.find(item => item.key === "About_Description")?.value || "<p>Default Description</p>";
    const aboutImage = settings.find(item => item.key === "about_image")?.value || about2;

    const circleStyle = {
        '--percent': 70,
    };
    const circleStyle2 = {
        '--percent': 60,
    };

    const circleStyle3 = {
        '--percent': 50,
    };

    const circleStyle4 = {
        '--percent': 75,
    };


    return (
        <section className="about-section" id="About">
            <div className="container">
                <div className="about-wrap">
                    <div className="image">
                        <div className="image-inner">
                            <img src={aboutImage} alt="About" className="white-img" />
                            <img src={aboutImage} alt="abimg2" className="dark-img" />
                        </div>
                    </div>
                    <div className="content">
                        <h2>{aboutSubtitle}</h2>
                        <h3>{aboutTitle}</h3>
                        {/* <p>I am a creative Designer. I use animation as a third dimension by which to simplify
                            experiences and kuiding thro each and every interaction. I’m not adding motion just to
                            spruce things up, but doing it in ways that.</p> */}
                        <div dangerouslySetInnerHTML={{ __html: aboutDescription }} />
                    </div>
                    {/* <div className="progras-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="progras-card">
                                    <div className="percent">
                                        <svg>
                                            <circle cx="70" cy="70" r="59"></circle>
                                            <circle cx="70" cy="70" r="59" style={circleStyle}></circle>
                                        </svg>
                                        <div className="number">
                                            <h3>75<span>%</span></h3>
                                        </div>
                                    </div>
                                    <div className="title">
                                        <h2>Website Design</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="progras-card">
                                    <div className="percent">
                                        <svg>
                                            <circle cx="70" cy="70" r="59"></circle>
                                            <circle cx="70" cy="70" r="59" style={circleStyle2}></circle>
                                        </svg>
                                        <div className="number">
                                            <h3>60<span>%</span></h3>
                                        </div>
                                    </div>
                                    <div className="title">
                                        <h2>App Design</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="progras-card">
                                    <div className="percent">
                                        <svg>
                                            <circle cx="70" cy="70" r="59"></circle>
                                            <circle cx="70" cy="70" r="59" style={circleStyle3}></circle>
                                        </svg>
                                        <div className="number">
                                            <h3>50<span>%</span></h3>
                                        </div>
                                    </div>
                                    <div className="title">
                                        <h2>Dashboard Design</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="progras-card">
                                    <div className="percent">
                                        <svg className="animate__rotateOut">
                                            <circle cx="70" cy="70" r="59"></circle>
                                            <circle cx="70" cy="70" r="59" style={circleStyle4}></circle>
                                        </svg>
                                        <div className="number">
                                            <h3>75<span>%</span></h3>
                                        </div>
                                    </div>
                                    <div className="title">
                                        <h2>Graphic Design</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>
            <div className="left-text">
                <h2 className="text-outline text-outline-hover" data-text="Creative">{aboutlogotoptitle}</h2>
            </div>
            <div className="right-text">
                <h2 className="text-outline text-outline-hover" data-text="Designer">{aboutlogobottomtitle}</h2>
            </div>
        </section>
    )
}

export default About;