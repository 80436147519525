import { React, useState, useEffect, useRef } from 'react';
import ProjectSingle from '../ProjectSingle/ProjectSingle';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { useData } from '../../contexts/DataContext';

const ProjectSection = (props) => {
    const { data, loading, error } = useData();

    const categories = data?.allData?.data?.categories || [];
    const portfolios = data?.allData?.data?.portfolios || [];

    const [open, setOpen] = useState(false);
    const [state, setState] = useState({});
    const [activeFilter, setActiveFilter] = useState('*');

    // Modal open/close handlers
    function handleClose() {
        setOpen(false);
    }

    const handleClickOpen = (item) => {
        setOpen(true);
        setState(item);
    };
    
    const containerRef = useRef(null);
    const iso = useRef(null);

    useEffect(() => {
        if (portfolios.length > 0) {
            imagesLoaded(containerRef.current, function () {
                iso.current = new Isotope(containerRef.current, {
                    itemSelector: '.filter-item',
                    layoutMode: 'fitRows',
                });
            });
            return () => {
                if (iso.current) {
                    iso.current.destroy();
                }
            };
        }
    }, [portfolios]);

    const handleFilterClick = (filterValue) => {
        setActiveFilter(filterValue);
        iso.current.arrange({ filter: filterValue });
    };

    return (
        <section className="portfolio-section gallery-filters" id="Portfolio">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h2>Portfolio</h2>
                            <h3>My Latest Works</h3>
                        </div>
                    </div>
                </div>
                {/* {/ Dynamic Category Filter Buttons /} */}
                <div className="portfolio-category">
                    <ul>
                        <li>
                            <button onClick={() => handleFilterClick('*')} className={activeFilter === '*' ? 'active' : ''}>All</button>
                        </li>
                        {categories.map((category) => (
                            <li key={category.id}>
                                <button
                                    onClick={() => handleFilterClick(`.${category.name.toLowerCase().replace(/\s+/g, '-')}`)}
                                    className={activeFilter === `.${category.name.toLowerCase().replace(/\s+/g, '-')}` ? 'active' : ''}
                                >
                                    {category.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* {/ Portfolio List /} */}
                <div className="portfolio-wrap">
                    <div className="row gallery-container" ref={containerRef}>
                        {portfolios.map((project) => (
                            <div
                                className={`filter-item col-lg-6 col-md-6 col-12 grid-item ${project.PortfolioCategory.toLowerCase().replace(/\s+/g, '-')}`}
                                key={project.id}
                            >
                                <div className="portfolio_card">
                                    <div className="image">
                                        <div onClick={() => handleClickOpen(project)}>
                                            <img src={project.image || 'default_image.png'} alt={project.title} />
                                        </div>
                                        <span>{project.title}</span>
                                    </div>
                                    <h2 onClick={() => handleClickOpen(project)}>{project.title}</h2>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* {/ Project Single Modal /} */}
            <ProjectSingle open={open} onClose={handleClose} title={state.title} Img={state.image} description={state.description} client={state.client} completion={state.completion} consult={state.consult} duration={state.duration} location={state.location} project_type={state.project_type} share={state.share} />
        </section>
    );
};

export default ProjectSection;